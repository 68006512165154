<!--
0 - 전체
P - 작성중(임시저장) > 어드민만 작성한거
I - 전자서명요청 > 어드민이 > 파트너한테 보낸거
G - 파트너 전자서명 완료
C - 계약체결완료
M - 중도종결
f - 계약완전종료(검수끝난거)
N - 어드민 계약취소
-->
<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">계약서</h2>
      </div>
      <div class="body_section">
        <Fold title="계약서 미리보기">
          <div class="pdf-viewer">
            <template v-if="pdfAllPages > 0">
              <Pdf
                v-for="i in pdfAllPages"
                :key="i"
                :page="i"
                :src="objectUrl"
                @page-loaded="onVuePdfPageLoaded"
                @error="onVuePdfError"
              />
            </template>
            <template v-if="isPdfError">
              <div class="pdfviewer-error">
                PDF 정보를 읽을수 없습니다.<br />
                <button class="pdfviewer-error__btn" @click="onClickPdfGenerator">
                  PDF 재생성 <span class="icon-realod material-icons"> sync </span>
                </button>
              </div>
            </template>
          </div>
        </Fold>
        <TableViewWithTitle title="계약서 다운로드">
          <template v-slot:body>
            <tr>
              <th id="readContract">계약서</th>
              <td colspan="3">
                <template v-if="attachFile.electronicDocumentFile.length > 0">
                  <FileView :dataList="[attachFile.electronicDocumentFile[0]]" />
                </template>
              </td>
            </tr>
            <tr>
              <th>계약서 첨부파일</th>
              <td>
                <template v-if="attachFile.contractFile.length > 0">
                  <FileView :dataList="attachFile.contractFile" />
                </template>
              </td>
              <th>발주서 첨부파일</th>
              <td>
                <template v-if="attachFile.purchaseOrderFile.length > 0">
                  <FileView :dataList="attachFile.purchaseOrderFile" />
                </template>
              </td>
            </tr>
          </template>
        </TableViewWithTitle>

        <template v-if="contractStatus !== 'N' || contractStatus !== 'T'">
          <TableViewWithTitle title="이행증권">
            <template v-slot:colgroup>
              <!-- 비율문구 -->
              <col style="width: 124px" />
              <!-- 비율 -->
              <col style="width: 80px" />
              <!-- 보증방법 -->
              <col style="width: 80px" />
              <!-- 보증방법 -->
              <col style="width: 160px" />
              <!-- 증권번호문구 -->
              <col style="width: 150px" />
              <!-- 증권번호 -->
              <col style="width: 248px" />
              <!-- 첨부파일문구 -->
              <col style="width: 150px" />
              <!-- 첨부파일 -->
              <col style="width: 248px" />
            </template>
            <template v-slot:body>
              <tr>
                <th>선급금보증비율(%)</th>
                <td>
                  {{ orderPayment.advanceRate | nullToDash }}
                </td>
                <th>보증방법</th>
                <td>
                  <SelectBox
                    :placeholder="'선택'"
                    :isDisabled="true"
                    :dataList="suretyDataList"
                    :value.sync="orderPayment.advancePaymentGuaranteeType"
                  />
                </td>
                <th>선급금보증증권 번호<span class="ico_purchase ico_star">필수항목</span></th>
                <td>
                  <Input
                    :isDisabled="computedAdvancePaymentSurety.id === 'E'"
                    :value.sync="orderPayment.advancePaymentSurety"
                  />
                </td>
                <th>선급금보증증권 첨부파일<span class="ico_purchase ico_star">필수항목</span></th>
                <td>
                  <FileWrite
                    :isDisabled="computedAdvancePaymentSurety.id === 'E'"
                    :canMultiple="false"
                    :dataList.sync="attachFile.advancePaymentSuretyAttachFile"
                    @fileUploaded="advancePaymentSuretyFileUploaded"
                    @fileRemoved="advancePaymentSuretyFileRemoved"
                  />
                </td>
              </tr>
              <tr>
                <th>계약보증비율(%)</th>
                <td>
                  {{ orderPayment.contractRate | nullToDash }}
                </td>
                <th>보증방법</th>
                <td>
                  <SelectBox
                    :placeholder="'선택'"
                    :dataList="suretyDataList"
                    :isDisabled="true"
                    :value.sync="orderPayment.contractGuaranteeType"
                  />
                </td>
                <th>계약이행증권 번호<span class="ico_purchase ico_star">필수항목</span></th>
                <td>
                  <Input
                    :isDisabled="computedContractSurety.id === 'E'"
                    :value.sync="orderPayment.contractSurety"
                  />
                </td>
                <th>계약이행증권 첨부파일<span class="ico_purchase ico_star">필수항목</span></th>
                <td>
                  <FileWrite
                    :isDisabled="computedContractSurety.id === 'E'"
                    :canMultiple="false"
                    :dataList.sync="attachFile.contractSuretyAttachFile"
                    @fileUploaded="contractSuretyFileUploaded"
                    @fileRemoved="contractSuretyFileRemoved"
                  />
                </td>
              </tr>
              <tr>
                <th>하자보증비율(%)</th>
                <td>
                  {{ orderPayment.defectRate | nullToDash }}
                </td>
                <th>보증방법</th>
                <td>
                  <SelectBox
                    :placeholder="'선택'"
                    :dataList="suretyDataList"
                    :isDisabled="true"
                    :value.sync="orderPayment.defectGuaranteeType"
                  />
                </td>
                <th>하자이행증권 번호 <span class="ico_purchase ico_star">필수항목</span></th>
                <td>
                  <Input
                    :isDisabled="computedDefectGuaranteeType.id === 'E'"
                    :value.sync="orderPayment.defectSurety"
                  />
                </td>
                <th>하자이행증권 첨부파일 <span class="ico_purchase ico_star">필수항목</span></th>
                <td>
                  <FileWrite
                    :isDisabled="computedDefectGuaranteeType.id === 'E'"
                    :canMultiple="false"
                    :dataList.sync="attachFile.defectSuretyAttachFile"
                    @fileUploaded="defectSuretyFileUploaded"
                    @fileRemoved="defectSuretyFileRemoved"
                  />
                </td>
              </tr>
              <tr>
                <th>지체상금</th>
                <td colspan="3">
                  <strong>{{ orderPayment.penaltyRate }}</strong>/1000
                </td>
                <th>하자보증기간</th>
                <td>
                  <strong>{{ orderPayment.warranty }}</strong> 개월
                </td>
                <td></td>
                <td></td>
              </tr>
            </template>
          </TableViewWithTitle>
        </template>

        <template v-if="false">
          <TableViewWithTitle title="이행증권">
            <template v-slot:colgroup>
              <!-- 비율문구 -->
              <col style="width: 124px" />
              <!-- 비율 -->
              <col style="width: 80px" />
              <!-- 보증방법 -->
              <col style="width: 80px" />
              <!-- 보증방법 -->
              <col style="width: 160px" />
              <!-- 증권번호문구 -->
              <col style="width: 150px" />
              <!-- 증권번호 -->
              <col style="width: 248px" />
              <!-- 첨부파일문구 -->
              <col style="width: 150px" />
              <!-- 첨부파일 -->
              <col style="width: 248px" />
            </template>
            <template v-slot:body>
              <tr>
                <th>선급금보증비율(%)</th>
                <td>
                  {{ orderPayment.advanceRate | nullToDash }}
                </td>
                <th>보증방법</th>
                <td>
                  <SelectBox
                    :placeholder="'선택'"
                    :isDisabled="true"
                    :dataList="suretyDataList"
                    :value.sync="orderPayment.advancePaymentGuaranteeType"
                  />
                </td>
                <th>선급금보증증권 번호</th>
                <td>
                  {{ orderPayment.advancePaymentSurety }}
                </td>
                <th>선급금보증증권 첨부파일</th>
                <td>
                  <FileView :dataList.sync="attachFile.advancePaymentSuretyAttachFile" />
                </td>
              </tr>
              <tr>
                <th>계약보증비율(%)</th>
                <td>
                  {{ orderPayment.contractRate | nullToDash }}
                </td>
                <th>보증방법</th>
                <td>
                  <SelectBox
                    :placeholder="'선택'"
                    :dataList="suretyDataList"
                    :isDisabled="true"
                    :value.sync="orderPayment.contractGuaranteeType"
                  />
                </td>
                <th>계약이행증권 번호</th>
                <td>
                  {{ orderPayment.contractSurety }}
                </td>
                <th>계약이행증권 첨부파일</th>
                <td>
                  <FileView :dataList.sync="attachFile.contractSuretyAttachFile" />
                </td>
              </tr>
              <tr>
                <th>하자보증비율(%)</th>
                <td>
                  {{ orderPayment.defectRate | nullToDash }}
                </td>
                <th>보증방법</th>
                <td>
                  <SelectBox
                    :placeholder="'선택'"
                    :dataList="suretyDataList"
                    :isDisabled="true"
                    :value.sync="orderPayment.defectGuaranteeType"
                  />
                </td>
                <th>하자이행증권 번호</th>
                <td>
                  {{ orderPayment.defectSurety }}
                </td>
                <th>하자이행증권 첨부파일</th>
                <td>
                  <FileView :dataList.sync="attachFile.defectSuretyAttachFile" />
                </td>
              </tr>
              <tr>
                <th>지체상금</th>
                <td colspan="3">
                  <strong>{{ orderPayment.penaltyRate }}</strong>/1000
                </td>
                <th>하자보증기간</th>
                <td>
                  <strong>{{ orderPayment.warranty }}</strong> 개월
                </td>
                <td></td>
                <td></td>
              </tr>
            </template>
          </TableViewWithTitle>
        </template>

        <template v-if="itemData.contractAgreeHistory && itemData.contractAgreeHistory.length > 0">
          <TableViewWithTitle title="계약내역">
            <template v-slot:colgroup>
              <!-- 동의여부 -->
              <col style="width: 80px" />
              <!-- 날짜 -->
              <col style="width: 160px" />
              <!-- 사유 -->
              <col />
            </template>
            <template v-slot:header>
              <th scope="col">동의여부</th>
              <th scope="col">날짜</th>
              <th scope="col">사유</th>
            </template>
            <template v-slot:body>
              <tr v-for="item in itemData.contractAgreeHistory" :key="item.agreeNum">
                <td :class="{ tc_red: !item.agreeYn }">
                  {{ item.agreeYn ? "동의" : "미동의" }}
                </td>
                <td class="align_center">{{ item.regDate | dateTimeStringFormat }}</td>
                <td class="td_preline" :class="{ tc_red: !item.agreeYn }">
                  {{ item.disagreeReason | nullToDash }}
                </td>
              </tr>
            </template>
          </TableViewWithTitle>
        </template>

        <template v-if="contractStatus === 'M' && itemData.changeReason">
          <TableViewWithTitle title="중도종결 사유">
            <template v-slot:body>
              <tr>
                <th>중도종결 사유</th>
                <td colspan="3" class="list_total td_preline">
                  {{ itemData.changeReason }}
                </td>
              </tr>
            </template>
          </TableViewWithTitle>
        </template>
      </div>
    </div>
    <Sticky>
      <div class="area_left">
        <button class="btn_fourthly btn_large" @click="onClickGoToList">목록</button>

        <template v-if="contractStatus !== 'F'">
          <template
            v-if="contractStatus === 'I' || contractStatus === 'G' || contractStatus === 'C'"
          >
            <button
              class="btn_primary btn_large"
              :disabled="!isContractRead"
              @click="onClickUpdateSurety"
            >
              이행증권갱신
            </button>
          </template>
        </template>
      </div>
      <div class="area_right">
        <template v-if="contractStatus === 'N'">
          <span class="btn_fifthly btn_large">취소한 계약입니다.</span>
        </template>

        <template v-if="contractStatus === 'I'">
          <!-- 검토요청 -->
          <div class="ellip-box">
            <button
              class="btn_tertiary btn_large"
              :disabled="!isContractRead"
              @click="onClickContractReasonPopup"
            >
              계약서 미동의
            </button>
            <button
              class="btn_primary btn_large"
              :disabled="!isContractRead"
              @click="onClickAgreeComfirm"
            >
              계약서 동의
            </button>
            <div
              v-if="!isContractRead"
              class="ellip-box__contents"
              :data-ellip="'계약서를 끝까지 읽어주세요.'"
            ></div>
          </div>
          <!--  @click="onClickAgreeComfirm" -->
        </template>
      </div>
    </Sticky>

    <template v-slot:popup>
      <ContractReasonPopup
        v-if="isContractReasonPopup"
        :disagreeReason.sync="disagreeReason"
        @onClickCloseContractReasonPopup="onClickCloseContractReasonPopup"
        @sendContractDisAgreee="sendContractDisAgreee"
      />

      <AlertPopup
        v-if="isAgreeComfirm"
        alertText="계약서를 동의 하시겠습니까?<br />이행증권이 있는지 다시 한번 확인해주세요."
        btnFourthlyText="취소"
        btnPrimaryText="동의"
        @onClickFourtyly="onClickCloseAlertComfirm"
        @onClickPrimary="sendContractAgreee"
      />
      <AlertPopup
        v-if="isUpdateSurety"
        alertText="이행증권을 수정 하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="동의"
        @onClickFourtyly="onClickCloseAlertComfirm"
        @onClickPrimary="sendUpdateSurety"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import Pdf from "vue-pdf";

import PageWithLayout from "@/components/layout/PageWithLayout";
import Fold from "@/components/common/fold/Fold";
import Sticky from "@/components/layout/content/Sticky";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Input from "@/components/common/input/Input";
import FileView from "@/components/common/file/FileView";
import FileWrite from "@/components/common/file/FileWrite";
import AlertPopup from "@/components/layout/popup/AlertPopup";
import SelectBox from "@/components/common/selectBox/SelectBox";

import ContractReasonPopup from "@/components/user/contract/popup/ContractReasonPopup";

import ApiService from "@/services/ApiService";

import PageMixin from "@/mixins/PageMixin";

import { ObjectURLPdf } from "@/utils/pdfUtils";

import LocalStorageManager from "@/LocalStorageManager";

import { mapState } from "vuex";

export default {
  name: "ContractInfoView",
  components: {
    Pdf,
    Fold,
    Input,
    FileWrite,
    FileView,
    TableViewWithTitle,
    SelectBox,
    Sticky,
    AlertPopup,
    PageWithLayout,

    ContractReasonPopup,
  },
  mixins: [PageMixin],

  data() {
    return {
      contractId: "",
      contractStatus: "",
      objectUrl: "",
      pdfAllPages: 1,
      isPdfError: false,
      isPdfLoaded: false,
      suretyDataList: [
        { id: "G", code: "G", desc: "보증보험증권" },
        { id: "M", code: "M", desc: "각서" },
        { id: "E", code: "E", desc: "보증증권면제" },
      ],
      itemData: {},
      orderPayment: {
        advancePaymentSurety: "",
        advancePaymentGuaranteeType: "E",
        contractSurety: "",
        contractGuaranteeType: "E",
        defectSurety: "",
        defectGuaranteeType: "E",
      },
      attachFile: {
        electronicDocumentFile: [],
        contractFile: [], //계약서 첨부 파일
        purchaseOrderFile: [], //발주서 첨부 파일
        advancePaymentSuretyAttachFile: [], // 선급금이행증권 첨부파일
        contractSuretyAttachFile: [], // 계약이행증권 첨부파일
        defectSuretyAttachFile: [], // 하자이행증권 첨부파일
      },
      advancePaymentSurety: "", // 선급금이행증권 코드
      contractSurety: "", // 계약이행증권 코드
      defectSurety: "", // 하자이행증권 코드
      disagreeReason: "",
      isContractReasonPopup: false, //미동의 팝업
      isAgreeComfirm: false, //동의 팝업
      isUpdateSurety: false, // 이행증권 업데이트팝업

      observer: null,
      isContractRead: false,
    };
  },
  computed: {
    ...mapState({
      shignedData: (state) => state.tradeSign.signedData,
    }),
    isContractInspect() {
      //계약검토 요청
      return this.contractStatus === "I";
    },
    isValidContractInspect() {
      const {
        advancePaymentSurety,
        advancePaymentGuaranteeType,
        contractSurety,
        contractGuaranteeType,
        defectSurety,
        defectGuaranteeType,
      } = this.orderPayment;

      const { advancePaymentSuretyAttachFile, contractSuretyAttachFile, defectSuretyAttachFile } =
        this.attachFile;
      let ret = { isValid: false, text: "" };
      if (
        advancePaymentGuaranteeType !== "E" &&
        (!advancePaymentSurety || advancePaymentSuretyAttachFile.length < 1)
      ) {
        ret = { isValid: false, text: "선급금보증증권을 입력해주세요" };
      } else if (
        contractGuaranteeType !== "E" &&
        (!contractSurety || contractSuretyAttachFile.length < 1)
      ) {
        ret = { isValid: false, text: "계약이행증권을 입력해주세요" };
      } else {
        ret = { isValid: true, text: "" };
      }
      // this.onClickCloseAlertComfirm();
      return ret;
    },
    computedAdvancePaymentSurety() {
      //    { id: 'G', code: 'G', desc: '보증보험증권' },
      const { advancePaymentGuaranteeType } = this.orderPayment;
      return this.suretyDataList.find((item) => item.id === advancePaymentGuaranteeType);
    },
    computedContractSurety() {
      //{ id: 'M', code: 'M', desc: '각서' },
      const { contractGuaranteeType } = this.orderPayment;
      return this.suretyDataList.find((item) => item.id === contractGuaranteeType);
    },
    computedDefectGuaranteeType() {
      //   { id: 'E', code: 'E', desc: '보증증권면제' },
      const { defectGuaranteeType } = this.orderPayment;
      return this.suretyDataList.find((item) => item.id === defectGuaranteeType);
    },
  },
  watch: {
    isPdfLoaded(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initObserve();
      }
    },
    isPdfError(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initObserve();
      }
    },
  },
  created() {
    const { id } = this.$route.params;
    this.contractId = id;
    this.getData(id);

    this.createdObserve();

    const userData = LocalStorageManager.shared.getUserData();
  },
  updated() {
    this.$nextTick(function () {
      // 모든 화면이 렌더링된 후 실행합니다.
    });
  },
  beforeDestroy() {
    this.disconnectObserve();
  },
  methods: {
    async getData(id) {
      const path = `${this.$apiPath.CONTRACT}/${id}`;
      const result = await ApiService.shared.getData(path);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      this.itemData = _.cloneDeep(data);
      this.contractStatus = data.contractStatus;
      this.orderPayment = this.itemData.orderPayment;

      this.attachFile = {
        ...this.attachFile,
        ...this.itemData.attachFile,
      };

      if (this.attachFile.electronicDocumentFile.length < 1) {
        this.alert("PDF 정보를 읽을수 없습니다.");
        //return;
        this.isPdfError = true;
      } else {
        const viewPDFName = this.attachFile.electronicDocumentFile[0]?.orgFile;
        const viewPDFPath = this.attachFile.electronicDocumentFile[0]?.sysFilePath;

        await this.getPDFFile({ viewPDFName, viewPDFPath });
      }
    },
    async getPDFFile(obj) {
      const { viewPDFName, viewPDFPath } = obj;
      const arrayBuffer = await ApiService.shared.getFile(
        `${this.$apiPath.ATTACH_FILE}/${viewPDFPath}`,
      );
      this.objectUrl = ObjectURLPdf(arrayBuffer);
      const pdfData = Pdf.createLoadingTask(this.objectUrl);
      pdfData.promise.then((pdfInfo) => {
        this.pdfAllPages = pdfInfo.numPages;
      });
    },
    onVuePdfPageLoaded(number) {
      if (this.pdfAllPages === number) {
        this.isPdfLoaded = true;
      }
    },
    onVuePdfError(error) {
      console.log("error :>> ", error);
      this.isPdfError = true;
      return;
    },

    advancePaymentSuretyFileUploaded(files) {
      this.attachFile.advancePaymentSuretyAttachFile = files;
    },
    advancePaymentSuretyFileRemoved(itemToRemove) {
      this.attachFile.advancePaymentSuretyAttachFile =
        this.attachFile.advancePaymentSuretyAttachFile.filter((item) => item !== itemToRemove);
    },
    contractSuretyFileUploaded(files) {
      this.attachFile.contractSuretyAttachFile = files;
    },
    contractSuretyFileRemoved(itemToRemove) {
      this.attachFile.contractSuretyAttachFile = this.attachFile.contractSuretyAttachFile.filter(
        (item) => item !== itemToRemove,
      );
    },
    defectSuretyFileUploaded(files) {
      this.attachFile.defectSuretyAttachFile = files;
    },
    defectSuretyFileRemoved(itemToRemove) {
      this.attachFile.defectSuretyAttachFile = this.attachFile.defectSuretyAttachFile.filter(
        (item) => item !== itemToRemove,
      );
    },
    onClickGoToList() {
      this.$router.push({
        path: this.$routerPath.CONTRACT_LIST,
        query: this.$route.query,
      });
    },
    onClickCloseAlertComfirm() {
      this.isAgreeComfirm = false;

      this.isUpdateSurety = false;
    },
    onClickAgreeComfirm() {
      // if (!this.isValidContractInspect.isValid) {
      //   this.alert(this.isValidContractInspect.text);
      //   return;
      // }
      this.isAgreeComfirm = true;
    },
    onClickUpdateSurety() {
      // if (!this.isValidContractInspect.isValid) {
      //   this.alert(this.isValidContractInspect.text);
      //   return;
      // }
      this.isUpdateSurety = true;
    },
    onClickContractReasonPopup() {
      this.isContractReasonPopup = true;
    },
    onClickCloseContractReasonPopup() {
      this.isContractReasonPopup = false;
    },
    async sendUpdateSurety() {
      if (!this.isValidContractInspect.isValid) {
        this.alert(this.isValidContractInspect.text);
        return;
      }
      const path = `${this.$apiPath.CONTRACT_SURETY}`;
      const obj = {
        ...this.orderPayment,
        attachFile: this.attachFile,
      };
      const result = await ApiService.shared.putData(path, obj);
      const { code, data, text } = result;
      if (code !== "200") {
        //        this.alert(text);
        this.alert(text);
        return;
      }
      this.isUpdateSurety = false;
      this.getData(this.contractId);
    },
    async sendContractDisAgreee() {
      const path = `${this.$apiPath.CONTRACT_REVIEW}/${this.contractId}`;
      const obj = {
        agreeYn: false,
        disagreeReason: this.disagreeReason,
      };
      const result = await ApiService.shared.putData(path, obj);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      this.isContractReasonPopup = false;
      this.getData(this.contractId);
    },
    async sendContractAgreee() {
      const {
        advancePaymentSurety,
        advancePaymentGuaranteeType,
        contractSurety,
        contractGuaranteeType,
        defectSurety,
        defectGuaranteeType,
      } = this.orderPayment;

      const { advancePaymentSuretyAttachFile, contractSuretyAttachFile, defectSuretyAttachFile } =
        this.attachFile;
      let ret = { isValid: false, text: "" };
      if (
        advancePaymentGuaranteeType !== "E" &&
        ((!advancePaymentSurety && advancePaymentSuretyAttachFile.length > 0) ||
          (advancePaymentSurety && advancePaymentSuretyAttachFile.length < 1))
      ) {
        ret = { isValid: false, text: "선급금보증증권을 입력해주세요" };
      } else if (
        contractGuaranteeType !== "E" &&
        ((!contractSurety && contractSuretyAttachFile.length > 0) ||
          (contractSurety && contractSuretyAttachFile.length < 1))
      ) {
        ret = { isValid: false, text: "계약이행증권을 입력해주세요" };
      } else {
        ret = { isValid: true, text: "" };
      }

      if (!ret.isValid) {
        this.alert(this.isValidContractInspect.text);
        return;
      }

      const signData = this.itemData;
      const obj = {
        agreeYn: true,
        orderPayment: this.orderPayment,
        attachFile: this.attachFile,
        signedData: JSON.stringify(signData),
      };

      if (process.env.NODE_ENV !== "production") {
        alert("트레이드사인 임시 해제상태 입니다.");
        const path = `${this.$apiPath.CONTRACT_REVIEW}/${this.contractId}`;
        const result = await ApiService.shared.putData(path, obj);
        const { code, data, text } = result;
        if (code !== "200") {
          this.alert(text);
          return;
        }
        this.isAgreeComfirm = false;
        this.getData(this.contractId);
      } else {
        this.$store.dispatch("ACTION_SEND_TRADESIGN", {
          signData: JSON.stringify(signData),
          returnCallback: this.sendContractSign,
        });
      }
    },

    async requestSign() {
      const userData = LocalStorageManager.shared.getUserData();

      const signData = {
        type: "contract",
        id: this.contractId,
        reqTime: this.moment().format("YYYYMMDDhhmmss"),
        corporationNumber: userData.username,
      };
      /* 임시 API 코드 */
      const path = `${this.$apiPath.CONTRACT_PARTNER_SIGN}`;

      const result = await ApiService.shared.postData(path, {
        cid: this.contractId,
        sigendData: signData,
      });

      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }

      if (data) {
        //전자서명 데이터 검증성공
        this.alert("전자서명이 완료되었습니다.");
        //window.location.reload();
      } else {
        //전자서명 데이터 검증실패
        this.alert("전자서명검증에 실패 하였습니다.<br />다시 한번 확인해 주세요");
        return;
      }
      //window.location.reload();
      /* //임시 API 코드 */

      return; //전자서명 될떄까지 임시 막음
    },

    async sendTradeSign() {
      const userData = LocalStorageManager.shared.getUserData();

      const signData = {
        data: this.itemData,
      };

      if (process.env.NODE_ENV !== "production") {
        /* 임시 API 코드 */
        alert("트레이드사인 임시 해제상태 입니다.");
        const path = `${this.$apiPath.CONTRACT_PARTNER_SIGN}`;

        const result = await ApiService.shared.postData(path, {
          cid: this.contractId,
          sigendData: JSON.stringify(signData),
        });

        const { code, data, text } = result;
        if (code !== "200") {
          this.alert(text);
          return;
        }
        //if(여기서 data.expYn 값이 true면 성공 아니면 실패)

        if (data) {
          //전자서명 데이터 검증성공
          this.alert("전자서명이 완료되었습니다.");
          window.location.reload();
        } else {
          //전자서명 데이터 검증실패
          this.alert("전자서명검증에 실패 하였습니다.<br />다시 한번 확인해 주세요");
          return;
        }

        return; //전자서명 될떄까지 임시 막음
      } else {
        this.$store.dispatch("ACTION_SEND_TRADESIGN", {
          signData: JSON.stringify(signData),
          returnCallback: this.sendContractSign,
        });
      }
    },
    async sendContractSign(signCb) {
      const { certInfo, signedData } = signCb; //signedData 서버에 줄값

      this.isAgreeComfirm = false;
      const path = `${this.$apiPath.CONTRACT_REVIEW}/${this.contractId}`;
      const obj = {
        agreeYn: true,
        orderPayment: this.orderPayment,
        attachFile: this.attachFile,
        signedData: signedData,
      };

      const result = await ApiService.shared.putData(path, obj);

      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      //if(여기서 data.expYn 값이 true면 성공 아니면 실패)

      if (data) {
        //전자서명 데이터 검증성공
        this.alert("전자서명이 완료되었습니다.");
        this.getData(this.contractId);
      } else {
        //전자서명 데이터 검증실패
        this.alert("전자서명검증에 실패 하였습니다.<br />다시 한번 확인해 주세요");
        return;
      }
    },

    createdObserve() {
      this.observer = new IntersectionObserver(this.onTargetObserved, {
        root: this.$el,
        threshold: 1.0,
      });
    },
    initObserve() {
      const $target = this.$el.querySelector("#readContract");
      if ($target) {
        this.observer.observe($target);
      }
    },
    disconnectObserve() {
      this.observer.disconnect();
    },
    onTargetObserved(entries) {
      entries.forEach(({ target, isIntersecting }) => {
        if (!isIntersecting) {
          return;
        }
        this.isContractRead = true;
        this.observer.unobserve(target);
      });
    },
    onClickPdfGenerator() {
      this.pdfGenerator();
    },
    async pdfGenerator() {
      const path = `${this.$apiPath.CONTRACT_PDF_GENERATOR}/${this.itemData.contractId}`;
      const result = await ApiService.shared.putData(path);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      window.location.reload();
    },
  },
};
</script>

<style scoped lang="scss">
// @import '~@/assets/css/print.css';
.box_print {
  padding: 75px 0 72px;
  background-color: #f5f5f5;
  border-top: 2px solid #222;
}
.wrap_print {
  overflow: hidden;
  position: relative;
  width: 1090px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 1px 2px 12px rgba(0, 0, 0, 0.2);
}
.page_print + .page_print {
  border-top: 1px solid #e1e1e1;
}

.pdf-viewer {
  position: relative;
  border-top: 2px solid #222;
  & > span {
    max-width: 1280px;
    margin: 0 auto;
    border-top: 3px solid #e1e1e1;
    &:first-child {
      border-top: none;
    }
  }
  & .pdfviewer-error {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    &__btn {
      margin-top: 15px;
      font-size: 18px;
      & > .icon-realod {
        vertical-align: -4px;
      }
    }
  }
}
.ellip-box {
  position: relative;
  &__contents {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
