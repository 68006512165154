<template>
  <Popup popupType="tableType" width="500px">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section">계약서 미동의</h3>
      </div>
      <div class="body_section">
        <TableViewWithTitle>
          <template v-slot:body>
            <tr>
              <th>미동의 사유</th>
              <td colspan="3">
                <Textarea
                  style="height: 120px"
                  placeholder="미동의 사유를 입력하세요."
                  :value.sync="disagreeReasonSync"
                />
              </td>
            </tr>
          </template>
        </TableViewWithTitle>
      </div>
    </div>
    <div class="group_btn">
      <button
        type="button"
        class="btn_fourthly btn_medium"
        @click="onClickCloseContractReasonPopup"
      >
        취소
      </button>
      <button
        class="btn_tertiary btn_medium"
        :disabled="isDisagreeDisabled"
        @click="onClickContractDisagreee"
      >
        미동의
      </button>
    </div>

    <AlertPopup
      v-if="isDisagreeeComfirm"
      alertText="계약서를 미동의 하시겠습니까?"
      btnFourthlyText="취소"
      btnPrimaryText="미동의"
      @onClickFourtyly="onClickCloseDisAagreeeComfirm"
      @onClickPrimary="sendContractDisAgreee"
    />
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import Textarea from "@/components/common/textarea/Textarea";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import AlertPopup from "@/components/layout/popup/AlertPopup";
import Sticky from "@/components/layout/content/Sticky.vue";

export default {
  name: "ContractReasonPopup",
  components: {
    Popup,
    Textarea,
    TableViewWithTitle,
    AlertPopup,
    Sticky,
  },
  props: {
    disagreeReason: String,
  },
  data() {
    return {
      isDisagreeeComfirm: false,
    };
  },
  computed: {
    disagreeReasonSync: {
      get() {
        return this.disagreeReason;
      },
      set(value) {
        this.$emit("update:disagreeReason", value);
      },
    },
    isDisagreeDisabled() {
      return this.disagreeReason.length > 0 ? false : true;
    },
  },
  methods: {
    onClickCloseContractReasonPopup() {
      this.$emit("onClickCloseContractReasonPopup");
    },
    onClickContractDisagreee() {
      this.isDisagreeeComfirm = true;
    },
    onClickCloseDisAagreeeComfirm() {
      this.isDisagreeeComfirm = false;
    },
    sendContractDisAgreee() {
      this.$emit("sendContractDisAgreee");
    },
  },
};
</script>
<style scoped></style>
